window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});



window.addEventListener('heyflow-submit', (event) => {
    
    // Extracting the relevant data from the event
    const fields = event.detail.fieldsSimple;
    const email = fields["Email address"];
    const phoneNumber = fields["Phone number"];
    let countryCode = fields["country-select-phone-6dc9e454"]; // Assuming this field contains the country code

    // Ensure countryCode is numeric
    countryCode = countryCode.replace(/\D/g, ''); // Remove non-numeric characters

    // Clean the phone number
    const revSpace = phoneNumber.replace(/\s/g, ''); // Remove spaces
    const cleanPhoneNumber = revSpace.replace(/^0+/, ''); // Remove any leading zeros

    const withCoutPhone = `+${countryCode}${cleanPhoneNumber}`;

    

    if (cleanPhoneNumber.length) {
        // Generate a random OTP
 
       document.getElementById("input-924ffcfc").value = Math.floor(1000 + Math.random() * 9000).toString();
        
        var settings = {
            "url": "https://api.twilio.com/2010-04-01/Accounts/ACa4cc18626538ccfd2087bcf253e16ddf/Messages.json",
            "method": "POST",
            "timeout": 10000,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic " + btoa('ACa4cc18626538ccfd2087bcf253e16ddf:0013b20c0a5569f19b3a810b8a936132')
            },
            "data": {
                "To": withCoutPhone,
                "From": "+61483982406", // Your Twilio number
                "Body": `Hello, your verification code for the car loan application is: ${document.getElementById("input-924ffcfc").value}`
            }
        };

        $.ajax(settings)
        .done(function(response) {
            console.log(response);
            if (response.status === 'queued') {
                setTimeout(function() {
                    $('#text-block-rich-text-35378aef').text('We sent the verification code to ' + withCoutPhone);
                    $('#text-block-rich-text-35378aef').css('color', 'green');
                }, 3000);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            console.error("Error: ", textStatus, errorThrown);
            console.error("Response: ", jqXHR.responseText);
            $('#text-block-rich-text-35378aef').text('Failed to send verification code. Please try again.');
            $('#text-block-rich-text-35378aef').css('color', 'red');
        });
    } else {
        alert('Please enter a valid number');
        setTimeout(function() {
            $(location).prop('href', '#phone')
        }, 3000);
    }
});
